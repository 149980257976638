<template>
  <div class="matchCourseWare">
    <div style="padding: 20px">
      <el-row>
        <el-col :span="19">
          <div class>
            <el-form :inline="true" label-width="78px" ref="searchForm">
              <el-form-item label="课件名称">
                <el-input
                  placeholder="请输入课件名称"
                  prefix-icon="el-icon-search"
                  clearable
                  v-model="inputKey"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="5">
          <div class>
            <el-button
              type="primary"
              @click="toDoSth(vm)"
              style="margin-left: 10px"
              >搜索</el-button
            >
            <el-button type="info" @click="resetCourseWare" plain>重置</el-button>
            <!-- @click="resetEvent" -->
          </div>
        </el-col>
      </el-row>
    </div>
    <el-table
      :data="tableData"
      stripe
      border
      style="width: 100%"
      max-height="450"
      align="center"
    >
      <el-table-column
        prop="courseClassifyName"
        label="课程分类"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.courseClassifyName"
            placement="top"
            effect="light"
            popper-class="pubToop"
          >
            <div class="ellipsis">
              {{ scope.row.courseClassifyName || "/" }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="courseName"
        label="课程名称"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.courseName"
            placement="top"
            effect="light"
            popper-class="pubToop"
          >
            <div class="ellipsis">{{ scope.row.courseName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="id"
        label="课件ID"
        align="center"
        width="60"
      ></el-table-column>
      <el-table-column
        prop="coursewareName"
        label="课件名称"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.coursewareName"
            placement="top"
            effect="light"
            popper-class="pubToop"
          >
            <div class="ellipsis">{{ scope.row.coursewareName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="school"
        label="来源学校"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column prop="mediaUri" label="在线预览" align="center">
        <template slot-scope="scope">
          <span class="clickWatch" @click="previewEvent(scope.row)"
            >点击查看</span
          >
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="lessonNum"
        label="课时数"
        align="center"
        width="80">
      </el-table-column>-->
      <el-table-column
        prop="sectionNum"
        label="章节数"
        align="center"
      ></el-table-column>
      <el-table-column prop="author" label="课件作者" align="center">
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.author"
            placement="top"
            effect="light"
            popper-class="pubToop"
          >
            <div class="ellipsis">{{ scope.row.author }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="isEnable" label="是否启用" align="center">
        <template slot-scope="scope">
          <el-checkbox
            @change="changeEvent(scope.row, scope.$index)"
            v-model="scope.row.isEnable"
          ></el-checkbox>
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        type="selection"
        width="55">
      </el-table-column>-->
    </el-table>

    <Pagination
      @changePage="changePage"
      :childMsg="childMsg"
    ></Pagination>

    <div class="btn_area">
      <el-button @click="closeCourse">关闭</el-button>
      <el-button class="mainBtn" @click="saveEvent">保存</el-button>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import way from "@/api/encapsulation";
const debounce = way.debounce;
export default {
  name: 'matchCourseWare',
  components: {
    Pagination
  },
  data() {
    return {
      formInline: {
        page: 1,
        pageSize: 10
      },
      form: {
        cancel: null,
        courseName: null,
        coursewareId: null,
        coursewareName: null,
        id: null,
        subjectId: null,
        tenantId: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      rowsData: {},
      vm: this,
      inputKey: null,
    }
  },
  methods: {
    toDoSth: debounce(
      vm => {
        // 这里将当前组件实例当参数传入
        // 就可以使用实例中定义的一些属性、方法
        // 补充一下，这里如果换成非箭头函数的写法，也可以直接访问实例。
        vm.getCourseData()
      },
      200,
      false
    ),
    resetCourseWare() {
      this.inputKey = null
      Object.assign(this.$data.formInline, this.$options.data().formInline)
    },
    sendData(obj) {
      this.rowsData = obj
      this.form.courseName = obj.courseName
      this.form.subjectId = obj.subjectId
      this.form.id = obj.id
      this.form.tenantId = obj.tenantId
      this.form.coursewareName = obj.coursewareName;
      this.form.coursewareId = obj.coursewareId;
      this.getCourseData()
    },
    // 获取课件表格数据
    getCourseData() {
      let name = this.inputKey || null
      let subjectId = this.rowsData.subjectId
      let obj = {
        name,
        subjectId,
        ...this.formInline
      };
      this.$request.getCoursewareList(obj, res => {
        this.newLoading = false;
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
          this.addSubscript();
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getCourseData();
    },
    // 题库，课件，教辅材料表格数据添加下标
    addSubscript() {
      this.tableData.forEach(item => {
        if (item.id == this.rowsData.coursewareId) {
          item.isEnable = true;
        }
      });
    },
    changeEvent(row, index) {
      this.$nextTick(() => {
        this.form.coursewareName = row.coursewareName;
        this.form.coursewareId = row.id;
        for (let i = 0; i < this.tableData.length; i++) {
          let newRow = JSON.parse(JSON.stringify(this.tableData[i]));
          newRow.isEnable = false;
          this.$set(this.tableData, i, newRow);
        }
        this.$set(this.tableData[index], "isEnable", row.isEnable);
        // if (!row.isEnable) {
        //   // 课件全部取消为2
        //   this.form.cancel = 2;
        //   this.newForm.coursewareName = null
        //   this.newForm.coursewareId = null
        // } else {
        //   this.form.cancel = null;
        //   this.newForm.coursewareName = row.coursewareName;
        //   this.newForm.coursewareId = row.id;
        // }
        this.form.cancel = !row.isEnable ? 2 : null
      })
    },
    // 在线预览 课件
    previewEvent(row) {
      this.$router.push({name: 'viewCoursewareModule', query: {
        courseId: row.id,
      }})
    },
    closeCourse() {
      this.$emit('dialogResEvent')
    },
    saveEvent() {
      this.$request.updateResourceTemplate(this.form).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || "保存成功");
          this.$emit('dialogResEvent')
          this.$emit('getTableData')
        }
      });
    }
  },
}
</script>

<style lang="less" scoped>
  .matchCourseWare {
    .btn_area {
      text-align: right;
      padding: 20px 0px;
      position: absolute;
      bottom: 0;
      right: 20px;
    }
    .el-table--scrollable-x .el-table__body-wrapper {
      overflow-x: hidden;
    }
    .clickWatch {
      color: #1282ff;
      cursor: pointer;
    }
  }
</style>