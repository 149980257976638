<template>
  <div class="resourceModule">
    <el-scrollbar class="elScroll">
      <div class="search_sList">
        <div class="top_opera">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            label-width="100px"
          >
            <el-form-item label="课程编码">
              <el-input
                v-model="formInline.id"
                placeholder="课程编码"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="原课程名称">
              <el-input
                v-model="formInline.name"
                placeholder="原课程名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
            <el-button type="info" plain @click="resetEvent">重置</el-button>
          </div>
        </div>
      </div>
      <div class="table_sList">
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
          align="center"
        >
          <el-table-column prop="courseId" label="课程编码" align="center">
            <!-- <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.id"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.id }}</div>
              </el-tooltip>
            </template> -->
          </el-table-column>
          <el-table-column prop="name" label="原课程名称" align="center">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.name"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.name }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="courseName" label="匹配课程名称" align="center">
            <!-- <template slot-scope="scope">
              <el-button size="small" type="text" @click="jumpEvent('coursemate', scope.row)"
                >{{scope.row.courseName || '/'}}</el-button
              >
            </template> -->
          </el-table-column>
          <el-table-column label="课件名称" align="center">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="jumpEvent('courseware', scope.row)"
                >{{scope.row.coursewareName || '/'}}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
      </div>
    </el-scrollbar>
    
    <dialogDiyNew :isShow="isShowRes" :dialogDiyData="dialogResData"
    @dialogEvent="dialogResEvent">
      <div slot="dialogContent" class="resource_area">
        <!-- <matchCourse ref="mc" @dialogResEvent="dialogResEvent" @getTableData="getTableData"></matchCourse> -->
        <component :is='comObj.title' :ref="comObj.refName" @dialogResEvent="dialogResEvent" @getTableData="getTableData"></component>
      </div>
    </dialogDiyNew>
    <Keydown @keydownEvent='handleKeydown'></Keydown>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import dialogDiyNew from '@/components/commonModule/dialogDiyNew'
import matchCourse from './matchCourse'
import matchCourseWare from './matchCourseWare'
export default {
  name: 'resourceModule',
  components: {
    Pagination,
    matchCourse,
    matchCourseWare,
    dialogDiyNew
  },
  data() {
    return {
      formInline: {
        id: null,
        name: null,
        page: 1,
        pageSize: 10,
        tenantId: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      isShowRes: false,
      dialogResData: {
        title: "",
        sizeStyle: { width: "1000px", height: "820px" }
      },
      isType: null,
      comObj: {
        title: null,
        refName: null
      }
    }
  },
  mounted() {
    this.formInline.tenantId = this.$route.query.tenantId
    this.getTableData()
  },
  methods: {
    handleKeydown(event) {
      if (!this.isShowRule) {
        this.onSubmit()
      }
    },
    // 搜索事件
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getTableData();
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
    },
    // 获取表格数据
    getTableData() {
      this.$request.resourceTemplateList(this.formInline).then((res) => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total,
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getTableData();
    },
    jumpEvent(type, row) {
      this.isType = type
      this.dialogResData.title = type == 'coursemate' ? '匹配课程' : '选择匹配课件'
      this.comObj.title = type == 'coursemate' ? 'matchCourse' : 'matchCourseWare'
      this.comObj.refName = type == 'coursemate' ? 'mc' : 'mcw'
      this.dialogResEvent()
      this.$nextTick(() => {
        let obj = {
          ...row,
          tenantId: this.formInline.tenantId
        }
        this.$refs[this.comObj.refName].sendData(obj)
      })
    },
    dialogResEvent() {
      this.isShowRes = !this.isShowRes
    }
  },
}
</script>

<style lang="less" scoped>
  .resourceModule {}
</style>