<template>
  <div class="matchCourse">
    <div class="search_erea">
      <el-input
        placeholder="请输入课程名称"
        prefix-icon="el-icon-search"
        v-model="formInline.name"
      ></el-input>
      <el-button
        type="primary"
        @click="toDoSth(vm)"
        style="margin-left: 10px"
        >搜索</el-button
      >
    </div>
    <el-button
      type="primary"
      @click="addNewCoursemate(vm)"
      style="margin-bottom: 10px"
      size="small"
      >新建课程</el-button
    >
    <el-table
      :data="tableData"
      stripe
      border
      style="width: 100%"
      max-height="480"
      align="center"
    >
      <el-table-column prop="name" label="课程名称" align="center">
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.name"
            placement="top"
            effect="light"
            popper-class="pubToop"
          >
            <div class="ellipsis">{{ scope.row.name }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="questionBankNum"
        label="题库数量"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="coursewareNum"
        label="课件数量"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="examPaperNum"
        label="试卷数量"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="ebookNum"
        label="教辅材料数量"
        align="center"
      ></el-table-column>
      <el-table-column prop="isEnable" label="是否启用" align="center">
        <template slot-scope="scope">
          <el-checkbox
            @change="changeEvent(scope.row, scope.$index)"
            v-model="scope.row.isEnable"
          ></el-checkbox>
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        type="selection"
        width="55">
      </el-table-column>-->
    </el-table>
    <Pagination
      @changePage="changePage"
      :childMsg="childMsg"
    ></Pagination>
    <div class="btn_area">
      <el-button @click="closeCourse">关闭</el-button>
      <el-button class="mainBtn" @click="saveEvent">保存</el-button>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import way from "@/api/encapsulation";
const debounce = way.debounce;
export default {
  name: 'matchCourse',
  components: {
    Pagination
  },
  data() {
    return {
      formInline: {
        name: null,
        page: 1,
        pageSize: 10
      },
      form: {
        cancel: null,
        courseName: null,
        coursewareId: null,
        coursewareName: null,
        id: null,
        subjectId: null,
        tenantId: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      rowsData: {},
      vm: this,
    }
  },
  methods: {
    toDoSth: debounce(
      vm => {
        // 这里将当前组件实例当参数传入
        // 就可以使用实例中定义的一些属性、方法
        // 补充一下，这里如果换成非箭头函数的写法，也可以直接访问实例。
        vm.getCourseNewData()
      },
      200,
      false
    ),
    sendData(obj) {
      this.rowsData = obj
      this.formInline.name = obj.courseName
      this.form.courseName = obj.courseName
      this.form.subjectId = obj.subjectId
      this.form.id = obj.id
      this.form.tenantId = obj.tenantId
      this.getCourseNewData()
    },
    // 获取课程表格数据
    getCourseNewData() {
      this.$request.getCourseList(this.formInline, res => {
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
          this.addSubscript();
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getCourseNewData();
    },
    addNewCoursemate() {
      console.log(this.rowsData,66);
      this.$confirm("确定要新建与原课程名称同名的课程并启用吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        // {courseId:}
        this.$request.addCourse({ courseId: this.rowsData.courseId }).then(res => {
          if (res.data.code == 0) {
            this.Success("新增成功");
            this.$emit('getTableData')
          }
        });
      });
    },
    // 题库，课件，教辅材料表格数据添加下标
    addSubscript() {
      this.tableData.forEach(item => {
        if (item.id == this.rowsData.subjectId) {
          item.isEnable = true;
        }
      });
    },
    changeEvent(row, index) {
      this.tableData.forEach((ite, ind) => {
        let newRow = JSON.parse(JSON.stringify(ite));
        newRow.isEnable = false;
        this.$set(this.tableData, ind, newRow);
      });
      this.$set(this.tableData[index], "isEnable", row.isEnable);
      this.form.courseName = row.name
      this.form.subjectId = row.id
      this.form.id = this.rowsData.id
      this.form.cancel = !row.isEnable ? 1 : null
    },
    closeCourse() {
      this.$emit('dialogResEvent')
    },
    saveEvent() {
      this.$request.updateResourceTemplate(this.form).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || "保存成功");
          this.$emit('dialogResEvent')
          this.$emit('getTableData')
        }
      });
    }
  },
}
</script>

<style lang="less" scoped>
  .matchCourse {
    .search_erea {
      text-align: center;
      .el-input {
        width: 300px;
        height: 50px;
        margin: 20px 0px;
        border-radius: 1px solid #e1e1e1;
      }
    }
    .btn_area {
      text-align: right;
      padding: 20px 0px;
      position: absolute;
      bottom: 0;
      right: 20px;
    }
    .el-table--scrollable-x .el-table__body-wrapper {
      overflow-x: hidden;
    }
  }
</style>